require( 'owl.carousel' );

export default function Carousels() {

	$('#header .owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        nav:false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        autoplayTimeout: 2000,
        slideTransition: 'linear',
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            900:{
                items:3
            },
			1200:{
                items:4
            }
        }
    })

	$( '#merch .owl-carousel' ).owlCarousel( {
		items: 3,
		loop: true,
		nav: true,
		dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        autoplayTimeout: 2000,
		autoplayHoverPause: true,
		navText:[
			'<img src = "./dist/img/Arrow.svg" alt = "previous">',
			'<img src = "./dist/img/Arrow.svg" alt = "next">'
		],
		margin: 20,
		responsive: {
			0: {
				items: 1,
			},
			768: {
				items: 3,
			}
		}
	} );
}